

.form-input{
    display: flex;
    flex-direction: column;
}
.form-input label{
    font-size: 20px;
    margin: 0;
}
.form-input input{
    max-width: 100%;
    height: 57px; /* max media screen size @ 77px */
    border: 2.5px solid var(--orange-color);
    border-radius: 20px;
    padding: 0 10px;
    font-size: 20px;
}