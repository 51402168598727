@import url('./../../App.css');

.marquee {
    --gap: 5px;
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    justify-content: center;
    align-items: center;
  }
  
  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
  }
  
  .marquee__content img {
    max-width: 2rem;
    width: 100%;
    object-fit: contain;
  }
  
  /* DEFAULT TEXT STYLES */
  .marquee__content > * {
    flex: 0 0 auto;
    color: black;
    margin: 2px;
    text-align: center;
    font-size: 40px;
    font-weight: 800;
  }
  
  .marquee__item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  ul { padding-left: 0; }

  .horizontal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .vertical{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg) !important;
    writing-mode: vertical-rl;
  }

  .enable-animation.horizontal .marquee__content {
    animation: scroll 60s linear infinite;
  }
  .enable-animation.vertical .marquee__content {
    animation: scrollVertical 60s linear infinite;
  }

  @keyframes scroll {
    from { transform: translateX(0); }
    to { transform: translateX(calc(-100% - var(--gap))); }
  }

  @keyframes scrollVertical {
    from { transform: translateY(0); }
    to { transform: translateY(calc(100% - var(--gap))); }
  }

  /* CUSTOM STYLING */
  .marquee__content > * {
    color: white;
    font-size: 60px;
    text-transform: uppercase;
  }
  .enable-animation{
    display: none;
    width: 172px;
  }
  .horizontal{

  }
  .vertical{
    background-color: var(--dark-color);
  }
  .responsive_VerticalToHorizontal{

  }
  .responsive_HorizontalToVertical{

  }

  @media (min-width:1024px) {
    .enable-animation{
      display: inherit;
    }
  }