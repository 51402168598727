#landing{
    background-color: var(--primary-color);
    background-image: url('./landing.png');
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

#overlay{
    z-index: 1;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.774);
    height: 100%;
    width: 100%;
    display: flex;
}
#BG_Element{
    width: 50%;
    height: auto;
    margin-top: auto;
}

#content{
    z-index: 2;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    padding: 0;
}
#content-head{
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
    gap: 30px;
    padding: 10px;
}
#content-head img{
    width:260px;
    height:260px;
    border-radius: 100%;
}
#content-title{
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: white;
    text-align: center;
}
#content-title h1{
    font-size: 40px;
    margin: 0;
}
#content-title h2{
    font-size: 20px;
    margin: 0;
    font-weight: 450;
}

@media (min-width: 1024px) {
    #BG_Element{
        width: 613px;
        height: 438px;
        margin-top: auto;
    }

    #content{
        padding: 50px 0 0 200px !important;
    }
    #content-head{
        flex-direction: row;
        justify-content: initial;
        align-items: initial;
        gap: 100px;
        padding: 50px 0 0 50px;
    }
    #content-title{
        text-align: right;
    }
    #content-title h1{
        font-size: 80px;
        margin: 0;
    }
    #content-title h2{
        font-size: 40px;
        margin: 0;
        font-weight: 450;
    }
}

@media (min-width:1400px){
    #content-title h1{
        font-size: 100px;
        margin: 0;
    }
    #content-title h2{
        font-size: 60px;
        margin: 0;
        font-weight: 450;
    }
}