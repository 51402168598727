
#footer--outer{
    padding: 0;
    background-color: #E8E8E8;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
}
#footer--inner{
    padding: 0;
    background-color: #B5B5B5;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
}
.footer{
    width: 100%;
    background-color: #141414;
    padding: 10px;
    font-size: 15px;
    color: white;
    text-align: center;
    height: auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-sizing: border-box;
}

.footer a{
    text-decoration: none;
    color: white;
    font-weight: bolder;
}

@media screen and (min-width: 1024px) {
    #footer--outer{
        padding: 50px 100px 0 100px;
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
    }
    #footer--inner{
        padding: 50px 100px 0 100px;
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
    }
    .footer{
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
    }
}

@media screen and (min-width: 1400px) {

}