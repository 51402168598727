.pointer{
    cursor: pointer;
    position: absolute;
    bottom: 12%;
    left: 45%;
    right: 45%;
    animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
