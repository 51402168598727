@import url("./../../App.css");
@import url('https://fonts.googleapis.com/css2?family=Holtwood+One+SC&display=swap');

/* 
__________________________________________
IDENTIFIERS
__________________________________________

ID:
- chooseMe
- underlay
- chooseMe-banner
- main
- main-bar
*/

#chooseMe{
    background-color: var(--primary-color);
    background-image: url('./WCM_BG.png');
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

#underlay{
    width: 100%;
    height: 100%;            
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;  /* for demo purpose  */
}
#chooseMe-banner{
    width: fit-content;
    position: absolute;
    bottom: 50px;
    right: 0;
    background-color: black;
    color: white;
    padding: 10px;
    text-align: initial;
}

#main{
    z-index: 9;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

#main-bar{
    width: 1075px;
    height: 300px;
    max-width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.771);
    display: flex;
    justify-content: right;
    align-items: center;
    position: sticky;
    top: 0;
    box-sizing: border-box;
}
#main-bar div{
    max-width: 683px;
    max-height: 100%;
    color: white;
    overflow-x: auto;
}
#main-bar div h2{
    margin-top: 0;
    font-size: 35px;
    font-weight: bold;
}
#main-bar div p{
    font-size: 18px;
}
#main-bar div p > span{
    font-family: "Holtwood One SC", serif;
    font-weight: 400;
    font-style: normal;
}

@media screen and (min-width: 1024px) {
    
    #chooseMe{
        height: 150vh;
    }

    #chooseMe-banner{
        width: fit-content;
        padding: 10px;
    }
    
    #main-bar{
        width: 1075px;
        height: 641px;
        max-width: 75%;
        padding: 50px;
    }
    #main-bar div{
        max-width: 683px;
        overflow: hidden;
    }
    #main-bar div h2{
        margin-top: 0;
        font-size: 80px;
    }
    #main-bar div p{
        font-size: 20px;
    }
}

@media screen and (min-width: 1400px) {

}