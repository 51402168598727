@import url('https://fonts.googleapis.com/css2?family=Holtwood+One+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+BE+WAL:wght@100..400&display=swap');

:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --red-color: #EF3F3F;
  --orange-color: #FFA600;
  --info-color: #17a2b8;
  --light-color: #F8F8F8;
  --dark-color: #1B1B1B;
  --green-color: #06C8B1;
}

.font-holtwoodOneSC {
  font-family: 'Holtwood One SC', serif;
}
.font-playwriteBEWAL {
  font-family: 'Playwrite BE WAL', cursive;
}