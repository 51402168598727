.productSelection_header--container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.productSelection_header > span:nth-child(1){
    font-size: 20px;
    text-transform: uppercase;
    font-weight: normal !important;
    margin: 0 0 5px 0;
}
.productSelection_header > p{
    font-size: 20px;
    font-weight: normal !important;
    margin: 0 0 20px 0;
}

#productSelection {
    display: grid;
    grid-template-areas: 
        'item1 item2' 
        'item3 item4';
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
}

.option {
    width: 200px;
    height: 50px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bolder;
    background-color: var(--light-color);
    color: black;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.328);
}

.option.selected {
    background-color: #FFA500; /* Yellow color for selected */
    color: black;
}
