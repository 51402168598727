@import url("./../../App.css");

/* 
__________________________________________
IDENTIFIERS
__________________________________________

ID:
- services
- top
- top--left -> h1 -> span (x2)
- top--right
- services-list
*/

#services{
    background: linear-gradient(180deg, var(--dark-color) 0%, var(--green-color) 47%, var(--dark-color) 96%);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

#top{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
}

#top--left{
    width: 100%;
    font-size: 30px;
    color: var(--light-color);
    font-weight: bolder;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.285);
    display: flex;
    align-items: start;
    justify-content: center;
    text-align: center;
}
#top--left h1{
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0;
    line-height: 45px;
    text-transform: uppercase;
}
#top--left h1 span:nth-child(1){
    font-size: 50px;
}
#top--left h1 span:nth-child(2){
    font-size: 30px;
    margin-top: 0;
}

#top--right{
    font-size: 18px;
    color: var(--light-color);
    text-align: center;
}

#services-list{
    display: grid;
    grid-template-areas: '1';
    gap: 20px;
    justify-content: center;
}

@media screen and (min-width: 1024px) {
    #services{
        background: linear-gradient(180deg, var(--dark-color) 0%, var(--green-color) 47%, var(--dark-color) 96%);
        padding: 100px;
        display: flex;
        flex-direction: column;
        gap: 100px;
    }

    #top{
        display: flex;
        flex-direction: row;
        gap: 100px;
        justify-content: space-between;
    }

    #top--left{
        width: fit-content;
        font-size: 50px;
        color: var(--light-color);
        font-weight: bolder;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.285);
        display: flex;
        align-items: start;
        justify-content: left;
        text-align: left;
    }
    #top--left h1{
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 0;
        line-height: 85px;
        text-transform: uppercase;
    }
    #top--left h1 span:nth-child(1){
        font-size: 100px;
    }
    #top--left h1 span:nth-child(2){
        font-size: 50px;
    }

    #top--right{
        font-size: 25px;
        color: var(--light-color);
        text-align: right;
    }

    #services-list{
        display: grid;
        grid-template-areas: '1 2 3 4' '5 6 7 8';
        gap: 100px;
    }
}

@media screen and (min-width: 1400px) {
    
}