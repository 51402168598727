

.formBtn{
    min-width: 117px;
    height: 67px;
    font-size: 30px;
    font-weight: bold;
    border: none;
    border-radius: 20px;
    padding: 10px;
    background-color: var(--orange-color);
    color: white;
}

.formBtn.red{
    background-color: var(--red-color);
}
.formBtn.capatilize{
    text-transform: uppercase;
}