@import url("./../../App.css");

/* 
__________________________________________
IDENTIFIERS
__________________________________________

ID:
- chooseMe
- underlay
- chooseMe-banner
- main
- main-bar
*/

#contact{
    background-color: white;
    padding: 20px;
}

#contact-top h2{
    font-size: 30px;
    font-weight: bold;
    color: var(--orange-color);
    margin: 0 0 10px 0; 
}
#contact-top a{
    width: 100%;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-decoration: none;
}

#contact-bottom{
    width: 100%;
    margin-top: 70px;
}
#contact-bottom > span:nth-child(1){
    font-size: 25px;
    font-weight: bold;
}
#contact-form{
    width: 100%;
    display: flex;
}
#contact-form--main-details{
    width: 50%;
    display: grid;
    grid-template-areas: 'fName fName lName lName' 'emailAddress emailAddress emailAddress emailAddress' 'sendBtn clearBtn blank blank';
    column-gap: 20px;
    row-gap: 10px;
}
#contact-form--product-details{
    width: 50%;
}
.form-input:nth-child(3){
    margin-bottom: 50px;
}

@media screen and (min-width: 1024px) {
    #contact{
        padding: 80px;
    }
    
    #contact-top h2{
        font-size: 100px;
    }
    #contact-top a{
        font-size: 30px;
    }
}

@media screen and (min-width: 1400px) {

}