@import url('./../../App.css');

/* 
__________________________________________
IDENTIFIERS
__________________________________________

CLASS:
- service-item
- service-item--title
- service-item-icon
- service-item--collaborator
*/

.service-item{
    background-color: var(--green-color);
    padding: 10px;
    min-width: fit-content;
    min-height: fit-content;
    width: 14.875rem;
    height: 13.25rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.131);
}
.service-item:hover{
    transition: all 1s;
    box-shadow: 0 4px 4px 0 black;
}
.service-item--title{
    margin: 0;
    font-size: 25px;
    color: white;
    font-weight: bolder;
}

.service-item-icon{
    height: 140px;
}

.service-item.collab:hover{
    background-color: var(--dark-color);
}